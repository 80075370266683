import './App.css';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactShadowRoot from 'react-shadow-root';

function App() {
  const [show, setShow] = useState(false);


  return (
    <div className="App">
      <header className="App-header">
        <h1>Conventional form</h1>
        <form action='/form/post' method='POST'>
          <input id="conventional-name" name="name" type="text" placeholder="Enter your name" />
          <input id="conventional-password" name="password" type="password" placeholder="Enter your password" />
          <input type='submit' value='Submit' />
        </form>
        <h1>Ajax form</h1>
        <form>
          <input id="ajax-name" type="text" placeholder="Enter your name" />
          <input id="ajax-password" type="password" placeholder="Enter your password" />
          <input type='submit' value='Submit' onClick={(e)=>{
            e.preventDefault();
            let name = document.getElementById('ajax-name').value;
            let password = document.getElementById('ajax-password').value;
            axios.post('/form/ajax', {name, password}).then((res)=>{
              alert(JSON.stringify(res.data));
            });
          }}/>
        </form>
      </header>
      <Button onClick={()=>setShow(true)}>Show Modal</Button>
      <Modal show={show}>
          <Modal.Header closeButton onHide={()=>setShow(false)}>THIS IS A MODAL</Modal.Header>
          <Modal.Body>
            <form id="modal-form">
              <input id="modal-ajax-name" type="text" placeholder="Enter your name" />
              <input id="modal-ajax-password" type="password" placeholder="Enter your password" />
              <input type='submit' value='Submit' onClick={(e)=>{
                e.preventDefault();
                let name = document.getElementById('modal-ajax-name').value;
                let password = document.getElementById('modal-ajax-password').value;
                axios.post('/form/ajax', {name, password}).then((res)=>{
                  alert(JSON.stringify(res.data));
                });
              }}/>
            </form>
          </Modal.Body>
      </Modal>
      <div id="shads">
        <ReactShadowRoot>
        <form id="shadow-form">
              <h1>Shadow root</h1>
              <input id="shadow-ajax-name" type="text" placeholder="Enter your name" />
              <input id="shadow-ajax-password" type="password" placeholder="Enter your password" />
              <input type='submit' value='Submit' onClick={(e)=>{
                e.preventDefault();
                let top = document.querySelector('#shads').shadowRoot;
                let name = top.querySelector('#shadow-ajax-name').value;
                let password = top.querySelector('#shadow-ajax-password').value;
                axios.post('/form/ajax', {name, password}).then((res)=>{
                  alert(JSON.stringify(res.data));
                });
              }}/>
            </form>
          </ReactShadowRoot>
      </div>
    </div>
  );
}

export default App;
